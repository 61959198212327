import Storage from '../services/Storage';
import UserRepo from "../repositories/UserRepository";
import UserStore from "../stores/UserStore";
import _ from 'lodash';
import moment from 'moment';
import i18n from '../config/i18n';

export default class AuthLogic {
    /**
     * @returns {Promise<any>}
     */
    static login({email, password}) {
        return new Promise((resolve, reject) => {
            UserRepo
                .login({email, password})
                .then((result) => {
                    this.setTokens(result);
                    this.loadUser()
                        .then((result) => {
                            i18n.locale = result.data.locale;
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error)
                        });
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    /**
     * @returns {Promise<any>}
     */
    static resetPassword({email}) {
        return new Promise((resolve, reject) => {
            UserRepo
                .resetPassword({email})
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    /**
     * @returns {Promise<any>}
     */
    static passwordFindToken(token) {
        return new Promise((resolve, reject) => {
            UserRepo
                .passwordFindToken(token)
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    /**
     * @returns {Promise<any>}
     */
    static passwordUpdate({email, password, token}) {
        return new Promise((resolve, reject) => {
            UserRepo
                .passwordUpdate({email, password, token})
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    // /**
    //  *
    //  * @param body
    //  * @returns {Promise<any>}
    //  */
    // static register(body) {
    //     return new Promise((resolve, reject) => {
    //         UserRepo
    //             .register(body)
    //             .then(() => resolve())
    //             .catch((error) => reject(error));
    //     });
    // }

    /**
     * @returns {Promise<any>}
     */
    static refreshToken(refreshToken) {
        return new Promise((resolve, reject) => {
            this.deleteRefreshToken();

            UserRepo
                .refreshToken(refreshToken)
                .then((result) => {
                    this.setTokens(result);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    static deleteRefreshToken() {
        let token = Storage.get('token');
        delete token.refresh_token;
        Storage.set('token', token);
    }

    /**
     *
     */
    static logout() {
        return new Promise((resolve, reject) => {
            if (this.getTokens()) {
                UserRepo.logout()
                    .then((result) => {
                        this.removeUser();
                        this.removeTokens();
                        UserStore.isLoggedIn = false;

                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    });
            }

            resolve()
        });
    }

    /**
     * @returns {boolean}
     */
    static hasAccessToken() {
        const hasAccessToken = this.getTokens();

        if (hasAccessToken) {
            UserStore.isLoggedIn = true;
        }

        return hasAccessToken
    }

    /**
     *
     */
    static loadUser() {
        return new Promise((resolve, reject) => {
            UserRepo
                .user()
                .then((result) => {
                    UserStore.user = result.data;
                    Storage.set('user', result.data);
                    resolve(result)
                })
                .catch((error) => reject(error))
        });
    }

    /**
     * @returns {string|undefined}
     */
    static user() {
        return Storage.get('user')
    }

    /**
     * @returns void
     */
    static removeUser() {
        Storage.remove('user')
    }

    /**
     * @param token
     */
    static setTokens(token) {
        token.espireDate = moment().add(token.expires_in, 'seconds');

        Storage.set('token', token);
        UserStore.isLoggedIn = true;
    }

    /**
     * @returns {string}
     */
    static getTokens() {
        return Storage.get('token')
    }

    /**
     * @returns {string}
     */
    static removeTokens() {
        Storage.remove('token')
    }

    static hasRole(role) {
        const user = this.user();

        return user && user.roles
            ? !!_.find(user.roles.data, {name: role})
            : false;
    }
}


import axios from 'axios';
import AuthLogic from "../logics/AuthLogic";
import Vue from 'vue';
import applyAppTokenRefreshInterceptor from "../services/RefreshToken";

export const EventBus = new Vue();

class Request {
    constructor() {
        this.client = axios.create({
            baseURL: process.env.VUE_APP_API_HOST,
            headers: {
                'Content-type': 'application/json',
                'Accept': `application/vnd.api.${process.env.VUE_APP_API_VERSION}+json`,
            },
            withCredentials: true,
        })

        applyAppTokenRefreshInterceptor(this.client)

        this.client.interceptors.request.use((config) => {
            const tokens = AuthLogic.getTokens();

            if (tokens) {
                config.headers['Authorization'] = `Bearer ${tokens.access_token}`;
            }

            return Promise.resolve(config)
        });
    }

    /**
     *
     * @param method
     * @param url
     * @param data
     * @param cancelToken
     * @returns {Promise<unknown>}
     */
    // This is where magic append https://github.com/axios/axios#request-method-aliases used for cancelToken
    make(method, url, data = {}, cancelToken = () => {}) {
        return new Promise((resolve, reject) => {
            let clientConfig = []
            if (method.toLowerCase() === 'get' || method.toLowerCase() === 'delete' || method.toLowerCase() === 'head') {
                clientConfig = [url, {...data, ...cancelToken}]
            } else {
                clientConfig = [url, data, cancelToken]
            }

            this.client[method.toLowerCase()](...clientConfig)
                .then((response) => resolve(response))
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        // console.log('Request canceled', error.message);
                    } else {
                        this.parseError(error)
                            .then((response) => resolve(response))
                            .catch((error) => reject(error))
                    }
                });
        });
    }

    /**
     * Check error response and launch custom action
     *
     * @param error
     */
    parseError(error) {
        return new Promise((resolve, reject) => {
            reject(error)
        })
    }
}

export default new Request();
